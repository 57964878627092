import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "accordion",
  id: "accordionNexio"
}
const _hoisted_2 = { class: "accordion-item" }
const _hoisted_3 = {
  class: "accordion-header",
  id: "headingrunTransaction"
}
const _hoisted_4 = {
  id: "runTransaction",
  class: "accordion-collapse collapse",
  "aria-labelledby": "headingrunTransaction",
  "data-bs-parent": "#accordionNexio"
}
const _hoisted_5 = { class: "accordion-body" }
const _hoisted_6 = { class: "card ab-card" }
const _hoisted_7 = { class: "card-body" }
const _hoisted_8 = { class: "accordion-item" }
const _hoisted_9 = {
  class: "accordion-header",
  id: "headingCards"
}
const _hoisted_10 = {
  id: "cards",
  class: "accordion-collapse collapse",
  "aria-labelledby": "headingCards",
  "data-bs-parent": "#accordionNexio"
}
const _hoisted_11 = { class: "accordion-body" }
const _hoisted_12 = { class: "card ab-card" }
const _hoisted_13 = { class: "card-body" }
const _hoisted_14 = { class: "accordion-item" }
const _hoisted_15 = {
  class: "accordion-header",
  id: "headingTransactions"
}
const _hoisted_16 = {
  id: "transactions",
  class: "accordion-collapse collapse",
  "aria-labelledby": "headingTransactions",
  "data-bs-parent": "#accordionNexio"
}
const _hoisted_17 = { class: "accordion-body" }
const _hoisted_18 = { class: "card ab-card" }
const _hoisted_19 = { class: "card-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AddTransactionComponent = _resolveComponent("AddTransactionComponent")!
  const _component_CardComponent = _resolveComponent("CardComponent")!
  const _component_TransactionComponent = _resolveComponent("TransactionComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, [
        _createElementVNode("button", {
          class: "accordion-button collapsed",
          type: "button",
          "data-bs-toggle": "collapse",
          "data-bs-target": "#runTransaction",
          "aria-expanded": "false",
          "aria-controls": "runTransaction",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.select(0)))
        }, " Run a Transaction ")
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              (_ctx.data.selected === 0)
                ? (_openBlock(), _createBlock(_component_AddTransactionComponent, {
                    key: 0,
                    patientId: _ctx.props.patientId,
                    onTransactionCompleted: _cache[1] || (_cache[1] = ($event: any) => (_ctx.transactionCompleted()))
                  }, null, 8, ["patientId"]))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("h2", _hoisted_9, [
        _createElementVNode("button", {
          class: "accordion-button collapsed",
          type: "button",
          "data-bs-toggle": "collapse",
          "data-bs-target": "#cards",
          "aria-expanded": "false",
          "aria-controls": "cards",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.select(1)))
        }, " Saved Cards ")
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              (_ctx.data.selected === 1)
                ? (_openBlock(), _createBlock(_component_CardComponent, {
                    key: 0,
                    ref: "cards",
                    patientId: _ctx.props.patientId,
                    onTransactionCompleted: _cache[3] || (_cache[3] = ($event: any) => (_ctx.transactionCompleted()))
                  }, null, 8, ["patientId"]))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("h2", _hoisted_15, [
        _createElementVNode("button", {
          class: "accordion-button collapsed",
          type: "button",
          "data-bs-toggle": "collapse",
          "data-bs-target": "#transactions",
          "aria-expanded": "false",
          "aria-controls": "transactions",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.select(2)))
        }, " Transactions ")
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, [
              _createVNode(_component_TransactionComponent, {
                ref: "transactions",
                patientId: _ctx.props.patientId
              }, null, 8, ["patientId"])
            ])
          ])
        ])
      ])
    ])
  ]))
}