import ApiService from "@/core/services/ApiService";
import { processErrors } from "./errorsProcessing";

export const url = "api/v1/transactions";

export async function getTransactions(request) {
  return await ApiService.post(url + "/getTransactions", request)
    .then((res) => {
      const items = res.data;
      return items;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function getSaveTransactioniFrame(request) {
  return await ApiService.post(url + "/getSaveTransactioniFrame", request)
    .then((res) => {
      const items = res.data;
      return items;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function saveTransaction(request) {
  return await ApiService.post(url + "/savetransaction", request)
    .then((res) => {
      const items = res.data;
      return items;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}