import ApiService from "@/core/services/ApiService";
import { processErrors } from "./errorsProcessing";

export const url = "api/v1/card";

export async function getCards(request) {
  return await ApiService.post(url + "/getCards", request)
    .then((res) => {
      const items = res.data;
      return items;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function getSaveCardiFrame(request) {
  return await ApiService.post(url + "/getSaveCardiFrame", request)
    .then((res) => {
      const items = res.data;
      return items;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function saveCard(request) {
  return await ApiService.post(url + "/saveCard", request)
    .then((res) => {
      const items = res.data;
      return items;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function runSavedCard(request) {
  return await ApiService.post(url + "/runSavedCard", request)
    .then((res) => {
      const items = res.data;
      return items;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function deleteCard(cardId, clientId) {
  return await ApiService.delete(url + "/" + cardId + "?clientId=" + clientId)
    .then((res) => {
      const items = res.data;
      return items;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

