
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import useFilters from "@/modules/common/useFilters";
import { getTransactions } from "@/api/transactions.api";


export interface Transaction {
  id: string;
  paymentId: string;
  transactionId: string;
  amount: string;
  attempts: string;
  status: string;
  transactionType: string;
  transactionDate: any;
  createdDate: any;
}

export interface Data {
  items: Transaction[];
}

export default defineComponent({
  name: "TransactionsComponent",
  props: ["patientId"],
  components: {},
  setup(props) {
    let organizationId = ref<string | null>("");
    const router = useRouter();
    const { orderList } = useFilters();

    let data = reactive<Data>({
      items: [],
    });

    onMounted(async () => {
      await getAll();
    });

    async function getAll() {
      data.items = await getTransactions({
        clientId: props.patientId,
      });
    }

    return {

      orderList,
      getAll,
      data,
    };
  },
});
