
import { defineComponent, nextTick, onMounted, onUnmounted, reactive, ref } from "vue";
import { uuid } from "vue-uuid";
import DecimalInput from "@/components/ABilling/DecimalInput.vue";
import { useRouter } from "vue-router";
import useFilters from "@/modules/common/useFilters";
import {
  deleteCard,
  getCards,
  getSaveCardiFrame,
  runSavedCard,
  saveCard,
} from "@/api/cards.api";

export interface Card {
  id: string;
  cardHolderName: string;
  cardType: string;
  expiration: string;
  createdDate: string;
  deletedDate: string;
  amount?: number;
  lastFour: any;
}

export interface Data {
  items: Card[];
  url?: string;
  isInProgress: boolean;
  isFormValid: boolean;
  isAddCard: boolean;
  cardForTransaction?: Card;
  cardTransactionInProgress: boolean;
}

export default defineComponent({
  name: "CardsComponent",
  props: ["patientId"],
  components: { DecimalInput },
  setup(props, ctx) {
    let organizationId = ref<string | null>("");
    const router = useRouter();
    const { orderList } = useFilters();

    let data = reactive<Data>({
      items: [],
      url: undefined,
      isInProgress: false,
      isFormValid: false,
      isAddCard: false,
      cardTransactionInProgress: false,
    });

    onMounted(async () => {
      window.addEventListener("message", messageListener);
      await getAll();
    });

    onUnmounted(async () => {
      window.removeEventListener("message", messageListener);
    });

    async function messageListener(event) {
      const eventData = event.data?.data;
      const eventType = event.data?.event;
      if (event.origin === process.env.VUE_APP_NEXIO_URL) {
        if (eventType === "formValidations") {
          data.isFormValid = eventData.isFormValid;
        }
        if (eventType === "cardSaved") {
          let savedCard = {
            CardToken: eventData.token.token,
            LastFour: eventData.token.lastFour,
            CardType: eventData.token.cardType,
            CardHolderName: eventData.card.cardHolderName,
            Expiration:
              eventData.card.expirationMonth +
              "/" +
              eventData.card.expirationYear,
          };
          try {
            await saveCard({
              clientId: props.patientId,
              card: savedCard,
            });
          } finally {
            data.url = undefined;
            data.isInProgress = false;
            data.isAddCard = false;
            await getAll();
          }
        }
      }
    }

    async function getAll() {
      data.items = await getCards({
        clientId: props.patientId,
      });
    }

    async function deleteItem(card) {
      await deleteCard(card.id, props.patientId);
      data.items = await getCards({
        clientId: props.patientId,
      });
    }

    async function runCard() {
      if (data.cardForTransaction) {
        data.cardTransactionInProgress = true;
        try {
          await runSavedCard({
            clientId: props.patientId,
            cardId: data.cardForTransaction.id,
            amount: data.cardForTransaction.amount,
            uniqueId: uuid.v4(),
          });
        } finally {
          data.cardTransactionInProgress = false;
          data.cardForTransaction.amount = undefined;
          data.cardForTransaction = undefined;
          ctx.emit("transactionCompleted");
        }
      }
    }

    function selectCard(card) {
      data.cardForTransaction = card;
    }

    async function saveCardIframe() {
      data.isInProgress = true;
      await nextTick();
      const saveIframe = window.document.getElementById("saveCardIFrame");
      if (saveIframe) {
        (saveIframe as any).contentWindow.postMessage(
          "posted",
          process.env.VUE_APP_NEXIO_URL
        );
      }
      return false; // keeps the form from auto submitting
    }

    async function getCardIframe() {
      data.isAddCard = true;
      await nextTick();
      const url = await getSaveCardiFrame({
        clientId: props.patientId,
      });
      data.url = url;
    }

    function cancel() {
      data.url = undefined;
      data.isInProgress = false;
      data.isAddCard = false;
    }

    return {
      deleteItem,
      cancel,
      runCard,
      orderList,
      saveCardIframe,
      selectCard,
      getCardIframe,
      getAll,
      data,
    };
  },
});
