
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
  ref,
} from "vue";
import { uuid } from "vue-uuid";
import { useRouter } from "vue-router";
import useFilters from "@/modules/common/useFilters";
import DecimalInput from "@/components/ABilling/DecimalInput.vue";
import {
  getSaveTransactioniFrame,
  saveTransaction,
} from "@/api/transactions.api";

export interface Transaction {
  id: string;
  paymentId: string;
  transactionId: string;
  amount: string;
  attempts: string;
  status: string;
  transactionType: string;
  transactionDate: any;
  createdDate: any;
}
export interface Data {
  url?: string;
  amount?: number;
  guid?: string;
  isInProgress: boolean;
  isFormValid: boolean;
}

export default defineComponent({
  name: "AddTransactionComponent",
  props: ["patientId"],
  components: { DecimalInput },
  setup(props, ctx) {
    const router = useRouter();
    const { orderList } = useFilters();

    const nexio = ref(null);

    let data = reactive<Data>({
      url: undefined,
      amount: 0,
      isInProgress: false,
      isFormValid: false,
    });

    onMounted(async () => {
      window.addEventListener("message", messageListener);
    });

    onUnmounted(async () => {
      window.removeEventListener("message", messageListener);
    });

    async function messageListener(event) {
      const eventData = event.data?.data;
      const eventType = event.data?.event;
      if (event.origin === process.env.VUE_APP_NEXIO_URL) {
        if (eventType === "formValidations") {
          data.isFormValid = eventData.isFormValid;
        }
        if (eventType === "processed") {
          let transaction = {
            PaymentId: eventData.id,
            Amount: eventData.amount,
            CardHolder: eventData.сardHolder,
            TransactionType: eventData.transactionType,
            TransactionDate: eventData.transactionDate,
            Status: eventData.transactionStatus,
            UniqueId: data.guid,
          };
          try {
            const res = await saveTransaction({
              clientId: props.patientId,
              Transaction: transaction,
            });
          } finally {
            data.guid = undefined;
            data.amount = 0;
            data.url = undefined;
            data.isInProgress = false;
            ctx.emit("transactionCompleted");
          }
        }
      }
    }

    async function addTransaction() {
      data.guid = uuid.v4();
      const url = await getSaveTransactioniFrame({
        clientId: props.patientId,
        uniqueId: data.guid,
        amount: data.amount,
      });
      data.url = url;
    }

    function cancel() {
      data.guid = undefined;
      data.amount = 0;
      data.url = undefined;
      data.isInProgress = false;
    }

    async function saveTransactionIframe() {
      data.isInProgress = true;
      await nextTick();
      const saveIframe = window.document.getElementById(
        "saveTransactionIframe"
      );
      if (saveIframe) {
        (saveIframe as any).contentWindow.postMessage(
          "posted",
          process.env.VUE_APP_NEXIO_URL
        );
      }
      return false; // keeps the form from auto submitting
    }

    const isAmountExists = computed(() => {
      return data.amount && data.amount > 0;
    });

    return {
      addTransaction,
      cancel,
      data,
      nexio,
      isAmountExists,
      orderList,
      saveTransactionIframe,
    };
  },
});
