
import { defineComponent, onMounted, ref, reactive, watch } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { Patient } from "./patient.model";
import CardComponent from "./../nexio/Cards.vue";
import TransactionComponent from "./../nexio/Transactions.vue";
import AddTransactionComponent from "./../nexio/AddTransaction.vue";

export interface Data {
  patient?: Patient;
  selected?: number;
}
export default defineComponent({
  name: "PatientNexioComponent",
  props: ["patientId"],
  components: {
    CardComponent,
    TransactionComponent,
    AddTransactionComponent,
  },
  setup(props, ctx) {
    let organizationId = ref<string | null>("");

    let data = reactive<Data>({
      selected: undefined,
    });

    const cards = ref(null);
    const transactions = ref(null);

    onMounted(async () => {
      organizationId.value = getOrganization();
    });

    function transactionCompleted() {
      if (transactions?.value) (transactions?.value as any).getAll();
    }

    function select(item) {
      if (data.selected == item) {
        data.selected = undefined;
      } else {
        data.selected = item;
      }
    }

    return {
      cards,
      data,
      transactions,
      props,
      organizationId,
      select,
      transactionCompleted,
    };
  },
});
